import * as api from '../api';

import React, { useEffect } from 'react';
import { logIn, logOut } from '../api';

import { auth } from './../api/firebase';

const ProfileContext = React.createContext({});

/**
 * useProfile - react hook for loading current user profile, making changes
 * @date 2020-03-05
 */

function useProfile() {
    const context = React.useContext(ProfileContext);
    if (context === undefined) {
        throw new Error(`useProfile must be used within a ProfileProvider`);
    }
    return context;
}

const ProfileProvider = props => {
    const [needsLogin, setNeedsLogin] = React.useState(false);

    const [shouldFetchFromLocalStorage, setShouldFetchFromLocalStorage] = React.useState(true);
    const [showTestData, setShowTestData] = React.useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            console.log('[profile_provider] auth state changed for user: ', user);
            refreshNeedsLogin();
        });
    }, []);

    useEffect(() => {
        if (!shouldFetchFromLocalStorage) {
            localStorage.setItem('config', JSON.stringify({ show_test_data: showTestData }));
        }
    }, [showTestData, shouldFetchFromLocalStorage]);

    useEffect(() => {
        if (shouldFetchFromLocalStorage) {
            const stored = localStorage.getItem('config')
                ? JSON.parse(localStorage.getItem('config'))
                : [];
            setShowTestData((stored || {}).show_test_data);
            setShouldFetchFromLocalStorage(false);
        }
    }, [shouldFetchFromLocalStorage]);

    const refreshNeedsLogin = () => {
        const authd = api.isLoggedIn() && auth && auth.currentUser && auth.currentUser.uid;
        setNeedsLogin(!authd);
        console.log('[profile_provider] refreshed needs_login: ', !authd);
    };

    return (
        <ProfileContext.Provider
            value={{
                needsLogin,
                refreshNeedsLogin,
                logIn,
                logOut,
                showTestData,
                setShowTestData
            }}
            {...props}
        />
    );
};

export { ProfileProvider, useProfile };
