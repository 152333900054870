// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

// This import loads the firebase namespace.
import firebase from 'firebase/app';

// Initialize Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyDLMdBibLyVRfXaph2sHIQhrMbe7CMox5c',
    authDomain: 'bach-df7bf.firebaseapp.com',
    databaseURL: 'https://bach-df7bf.firebaseio.com',
    projectId: 'bach-df7bf',
    storageBucket: 'bach-df7bf.appspot.com',
    messagingSenderId: '187762998776',
    appId: '1:187762998776:web:764d24bc012a36e1b3ea0d',
    measurementId: 'G-PXL65HTE5W'
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const functions = firebase.functions();

export { auth, functions };
