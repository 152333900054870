import { Button, Checkbox } from '@material-ui/core';
import { NavLink, Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ActivityPage from '../pages/ActivityPage';
import AppBar from '@material-ui/core/AppBar';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CssBaseline from '@material-ui/core/CssBaseline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { ExitToApp } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ParticipantsPage from '../pages/ParticipantsPage';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import ReportsPage from '../pages/ReportsPage';
import ResponsesPage from '../pages/ResponsesPage';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from './../assets/zema_dashboard_logo.png';
import { useHistory } from 'react-router-dom';
import { useProfile } from '../providers/ProfileProvider';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
        backgroundColor: '#4A1D40'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));

function AppNavigation(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { logOut } = useProfile();
    const { showTestData, setShowTestData } = useProfile();
    const history = useHistory();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const signOut = async () => {
        await logOut();
        history.push(`/login`);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 64,
                    backgroundColor: '#4A1D40',
                    display: 'block'
                }}
            />
            <div
                style={{
                    height: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img src={logo} alt="Logo" style={{ marginBottom: 0 }} />
                <h5>Zema eDiary</h5>
            </div>
            <Divider />
            <List>
                <ListItem button key={'Activity'}>
                    <NavLink
                        to={'/activity'}
                        style={{
                            color: '#66788A',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        activeStyle={{ color: '#4A1D40' }}
                    >
                        <ListItemIcon style={{ color: 'inherit' }}>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Activity'} />
                    </NavLink>
                </ListItem>
                <ListItem button key={'Participants'}>
                    <NavLink
                        to={'/participants'}
                        style={{
                            color: '#66788A',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        activeStyle={{ color: '#4A1D40' }}
                    >
                        <ListItemIcon className="nav-icon" style={{ color: 'inherit' }}>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Participants'} />
                    </NavLink>
                </ListItem>
                <ListItem button key={'Reports'}>
                    <NavLink
                        to={'/reports'}
                        style={{
                            color: '#66788A',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        activeStyle={{ color: '#4A1D40' }}
                    >
                        <ListItemIcon style={{ color: 'inherit' }}>
                            <ShoppingBasketIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Reports'} />
                    </NavLink>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key={'Support'} disabled>
                    <NavLink
                        to={'/support'}
                        style={{
                            color: '#66788A',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <ListItemIcon style={{ color: 'inherit' }}>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Support'} />
                    </NavLink>
                </ListItem>
                <ListItem button key={'Sign Out'} style={{ marginTop: 30 }}>
                    <Button info onClick={() => signOut()} style={{ margin: -8 }}>
                        <ListItemIcon style={{ color: '#B2BBC4' }}>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Sign Out'}
                            style={{ color: '#B2BBC4', textTransform: 'none' }}
                        />
                    </Button>
                </ListItem>
            </List>

            <ListItem button key={'TestData'} style={{ marginTop: 30 }}>
                <Checkbox
                    checked={showTestData}
                    onChange={e => setShowTestData(e.target.checked)}
                    name="testData"
                    color="primary"
                />
                <ListItemText primary={'Show Test Data'} />
            </ListItem>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />

            <Router>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            ZEMA eDIARY
                        </Typography>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="pages">
                    {/* The implementation can be swapped with js to avoid SEO duplication of NavLinks. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/activity" />
                            </Route>
                            {/* this entire route is protected, so login should redirect */}
                            <Route exact path="/login">
                                <Redirect to="/activity" />
                            </Route>
                            <Route path="/activity">
                                <ActivityPage />
                            </Route>
                            <Route exact path="/participants">
                                <ParticipantsPage />
                            </Route>
                            <Route path="/participants/:id" children={<ResponsesPage />} />
                            <Route path="/reports" children={<ReportsPage />} />
                            <Route path="/support">
                                <h4>Hi</h4>
                            </Route>
                        </Switch>
                    </div>
                </main>
            </Router>
        </div>
    );
}

AppNavigation.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
};

export default AppNavigation;
