import 'react-dropdown/style.css';

import * as React from 'react';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    Snackbar
} from '@material-ui/core';
import { useEffect, useState } from 'react';

import { CSVLink } from 'react-csv';
import { DataGrid } from '@material-ui/data-grid';
import Dropdown from 'react-dropdown';
import GetAppIcon from '@material-ui/icons/GetApp';
import { functions } from '../api/firebase';
import { useProfile } from '../providers/ProfileProvider';
// import { GetActivities } from '../api';
import useSWR from 'swr';

const reportTypes = [
    { value: 'surveys', label: 'Survey Report' },
    { value: 'activity', label: 'Activity Report' },
    { value: 'compliance', label: 'Participants Report' }
];

const surveyTypes = [
    { value: null, label: 'All Surveys' },
    { value: '923d1d38b', label: 'Feeding A' },
    { value: '923d1d38bB', label: 'Feeding B' },
    { value: 'we2ewffjn20gf2', label: 'Colic' },
    { value: '32r34v56g5b6', label: 'BISQR' },
    { value: 'solid', label: 'Solid Food Introduction' }
];

const columns = [
    {
        field: 'type',
        headerName: 'TYPE',
        width: 150,
        headerClassName: 'grid-header',
        valueFormatter: row =>
            ((reportTypes.filter(v => v.value === row.value) || [])[0] || {}).label || row.value
    },
    {
        field: 'survey_id',
        headerName: 'SUBTYPE',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: row =>
            ((surveyTypes.filter(v => v.value === row.value) || [])[0] || {}).label || ''
    },
    {
        field: 'started_date',
        headerName: 'STARTED',
        width: 200,
        valueFormatter: v => new Date(v.value * 1000).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'status',
        headerName: 'STATUS',
        width: 100,
        headerClassName: 'grid-header',
        renderCell: params => (
            <p
                style={{
                    color:
                        params.value === 'pending'
                            ? 'orange'
                            : params.value === 'failed'
                            ? 'red'
                            : 'green',
                    textTransform: 'capitalize'
                }}
            >
                {params.value}
            </p>
        )
    },
    {
        field: 'public_url',
        headerName: 'GENERATE',
        width: 250,
        headerClassName: 'grid-header',
        renderCell: params => (params.value ? <div /> : <Button color="primary">CSV</Button>)
    },
    {
        field: 'public_url',
        headerName: 'DOWNLOAD',
        width: 250,
        headerClassName: 'grid-header',
        renderCell: params =>
            !params.value ? (
                <div />
            ) : (
                <a color="primary" href={params.value}>
                    CSV
                </a>
            )
    }
];

export default function ReportsPage() {
    const [refresh, setRefresh] = useState(false);
    const { data, error } = useSWR(refresh ? null : 'reports', { refreshInterval: 5 * 60 * 1000 });

    const [rows, setRows] = useState([]);
    const { showTestData } = useProfile();

    const [createReportType, setCreateReportType] = useState();
    const [createReportFilter, setCreateReportFilter] = useState();
    const [shouldCreateReport, setShouldCreateReport] = useState(false);

    const [currentReport, setCurrentReport] = useState();
    const [reportData, setReportData] = useState(false);

    const { createReportError } = useSWR(
        createReportType && shouldCreateReport
            ? `createReport/type/${createReportType}${
                  createReportFilter
                      ? `/${Object.keys(createReportFilter)[0]}/${
                            createReportFilter[Object.keys(createReportFilter)[0]]
                        }`
                      : ''
              }${showTestData === false ? '/show_test_data/false' : ''}`
            : null
    );

    // custom sort order for columns in reports
    // a) iterate each row, and emity the sorted column object
    // by reducing a pre-sorted array of known columns.
    const reportDataTransform = reportDatas =>
        (reportDatas || []).map(reportData =>
            Object.entries(reportData).reduce((a, [key, value]) => {
                const getValue = forKey => (key === forKey ? value : a[forKey]);
                const columns = [
                    'uid',
                    'edid',
                    'invite_code',
                    'timestamp',
                    'subject_id',
                    'study_day',
                    'start_date',
                    'description',
                    'version',
                    'type',
                    'available_date',
                    'completed_date',
                    'last_update',
                    'feeding',
                    'colic',
                    'bisqr',
                    'feeding_percent',
                    'deviated_count',
                    'colic_percent',
                    'bisqr_percent',
                    's1',
                    's2',
                    's3',
                    's4',
                    's5',
                    's6',
                    's7',
                    's8',
                    'solids',
                    'compliance_percent',
                    'compliance',
                    'survey_name',
                    'scheduled_for',
                    'introduced_on',
                    'c',
                    'lang',
                    'study_diary_version',
                    'F1',
                    'F1_question_text',
                    'F1_answer',
                    'F1_answer_type',
                    'F2',
                    'F2_question_text',
                    'F2_answer',
                    'F2_answer_type',
                    'F3',
                    'F3_question_text',
                    'F3_answer',
                    'F3_answer_type',
                    'C1',
                    'C1_question_text',
                    'C1_answer',
                    'C1_answer_type',
                    'C2',
                    'C2_question_text',
                    'C2_answer',
                    'C2_answer_type',
                    'C3',
                    'C3_question_text',
                    'C3_answer',
                    'C3_answer_type',
                    'C4',
                    'C4_question_text',
                    'C4_answer',
                    'C4_answer_type',
                    'C5',
                    'C5_question_text',
                    'C5_answer',
                    'C5_answer_type',
                    'C6',
                    'C6_question_text',
                    'C6_answer',
                    'C6_answer_type',
                    'B1',
                    'B1_question_text',
                    'B1_answer',
                    'B1_answer_type',
                    'B2',
                    'B2_question_text',
                    'B2_answer',
                    'B2_answer_type',
                    'B3',
                    'B3_question_text',
                    'B3_answer',
                    'B3_answer_type',
                    'B4',
                    'B4_question_text',
                    'B4_answer',
                    'B4_answer_type',
                    'B5',
                    'B5_question_text',
                    'B5_answer',
                    'B5_answer_type',
                    'B6',
                    'B6_question_text',
                    'B6_answer',
                    'B6_answer_type',
                    'B7',
                    'B7_question_text',
                    'B7_answer',
                    'B7_answer_type',
                    'B8',
                    'B8_question_text',
                    'B8_answer',
                    'B8_answer_type',
                    'B9',
                    'B9_question_text',
                    'B9_answer',
                    'B9_answer_type',
                    'B10',
                    'B10_question_text',
                    'B10_answer',
                    'B10_answer_type',
                    'B11',
                    'B11_question_text',
                    'B11_answer',
                    'B11_answer_type',
                    'B12a',
                    'B12b',
                    'B12c',
                    'B12d',
                    'B12e',
                    'B12_question_text',
                    'B12_answer',
                    'B12_answer_type',
                    'B13',
                    'B13_question_text',
                    'B13_answer',
                    'B13_answer_type',
                    'B14',
                    'B14_question_text',
                    'B14_answer',
                    'B14_answer_type',
                    'B15',
                    'B15_question_text',
                    'B15_answer',
                    'B15_answer_type',
                    'B16',
                    'B16_question_text',
                    'B16_answer',
                    'B16_answer_type',
                    'B17',
                    'B17_question_text',
                    'B17_answer',
                    'B17_answer_type',
                    'B18',
                    'B18_question_text',
                    'B18_answer',
                    'B18_answer_type',
                    'B19',
                    'B19_question_text',
                    'B19_answer',
                    'B19_answer_type',
                    'B20',
                    'B20_question_text',
                    'B20_answer',
                    'B20_answer_type',
                    'app_version',
                    'os_type',
                    'os_version'
                ].filter(k => Object.keys(reportData).includes(k));
                return columns.reduce((a, col) => ({ ...a, [col]: getValue(col) }), {});
            }, {})
        );
    useEffect(() => {
        console.log('WATCH', createReportType, createReportFilter);
    }, [createReportFilter, createReportType]);

    useEffect(() => {
        if (data && data.results) {
            const mapped = data.results.map(record => ({ ...record }));
            setRows(mapped);
        }
    }, [data]);

    useEffect(() => {
        if (currentReport) {
            (async () => {
                try {
                    const report = await functions
                        .httpsCallable('reportData')
                        .call({}, { report_id: currentReport });
                    setReportData(reportDataTransform(report.data.results));
                } catch (e) {
                    console.log(e);
                }
            })();
            setReportData();
        }
    }, [currentReport]);

    useEffect(() => {
        if (error) {
            console.log('[activity] request error: ', error);
        }
    }, [error]);

    const onCellClick = v => {
        if (!v.data.public_url) setCurrentReport((v.data || {}).id || (v.row || {}).id);
    };

    // reports each have their own filter dropdowns
    const onSurveyDropdownSelect = v => {
        setCreateReportType('surveys');
        setCreateReportFilter({ ...(v.value == null ? {} : { survey_id: v.value }) });
    };

    const onComplianceDropdownSelect = () => {
        setCreateReportType('compliance');
        setCreateReportFilter(null);
    };

    const onActivityDropdownSelect = () => {
        setCreateReportType('activity');
        setCreateReportFilter(null);
    };

    const createReport = type => {
        if (shouldCreateReport) {
            return;
        }
        setCreateReportType(type);
        setTimeout(() => {
            setShouldCreateReport(true);
        }, 100);
        setTimeout(() => {
            setShouldCreateReport(false);
            setRefresh(true);
        }, 1500);
        setTimeout(() => {
            setRefresh(false);
        }, 1600);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 0
            }}
        >
            <Grid container flexDirection="column" xs={12}>
                <Grid item xs={8}>
                    <h2>REPORTS</h2>
                    <p style={{ textAlign: 'left', marginBottom: '3em' }}>
                        Once you select a report to generate, it may take up to 10 minutes to create
                        the report. A button to download the CSV will appear when ready.
                    </p>
                </Grid>
            </Grid>
            <Grid
                style={{
                    backgroundColor: '#EEE',
                    borderRadius: '6pt',
                    padding: 20,
                    flexDirection: 'column',
                    display: 'flex',
                    marginBottom: 30
                }}
                xs={8}
                m={2}
                p={2}
            >
                <Grid
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}
                >
                    <h3 style={{ width: '200pt', textAlign: 'left' }}>Survey Report</h3>
                    <Dropdown
                        options={surveyTypes}
                        onChange={onSurveyDropdownSelect}
                        placeholder="All Surveys"
                        style={{ width: 50 }}
                    />
                    <Button
                        info
                        style={{
                            backgroundColor: '#4A1D40',
                            color: 'white',
                            marginLeft: 30,
                            paddingLeft: 30,
                            paddingRight: 30
                        }}
                        onClick={() => createReport('surveys')}
                    >
                        Generate
                    </Button>
                </Grid>
                <Grid
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}
                >
                    <h3 style={{ width: '200pt', textAlign: 'left' }}>Participants Report</h3>
                    <Dropdown
                        options={['All Users']}
                        onChange={onComplianceDropdownSelect}
                        placeholder="All Users"
                        style={{ width: 50 }}
                    />
                    <Button
                        info
                        style={{
                            backgroundColor: '#4A1D40',
                            color: 'white',
                            marginLeft: 30,
                            paddingLeft: 30,
                            paddingRight: 30
                        }}
                        onClick={() => createReport('compliance')}
                    >
                        Generate
                    </Button>
                </Grid>
                <Grid
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}
                >
                    <h3 style={{ width: '200pt', textAlign: 'left' }}>Activity Report</h3>
                    <Dropdown
                        options={['All Users']}
                        onChange={onActivityDropdownSelect}
                        placeholder="All Users"
                        style={{ width: 50 }}
                    />
                    <Button
                        info
                        style={{
                            backgroundColor: '#4A1D40',
                            color: 'white',
                            marginLeft: 30,
                            paddingLeft: 30,
                            paddingRight: 30
                        }}
                        onClick={() => createReport('activity')}
                    >
                        Generate
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={error || createReportError}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{
                    backgroundColor: 'red',
                    width: '100%'
                }}
            >
                <h4>Error: {error || createReportError}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress style={{ marginBottom: 30 }} />}

            {currentReport && !reportData && <CircularProgress style={{ marginBottom: 30 }} />}
            {data && (
                <div style={{ height: '60vh' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        onCellClick={onCellClick}
                    />
                </div>
            )}
            <Dialog
                onClose={() => {
                    setCurrentReport();
                }}
                open={currentReport && reportData}
            >
                <DialogTitle id="simple-dialog-title">Download Report</DialogTitle>
                {(!reportData || Object.values(reportData).length < 1) && (
                    <h5 style={{ color: 'gray', marginTop: 2, textAlign: 'center', padding: 10 }}>
                        Sorry, there are currently
                        <br />
                        no data entires for this report
                    </h5>
                )}
                {reportData && Object.values(reportData).length > 0 && (
                    <List>
                        <ListItem autoFocus style={{ justifyContent: 'center' }}>
                            <ListItemAvatar>
                                <GetAppIcon />
                            </ListItemAvatar>
                            <CSVLink
                                style={{ textDecoration: 'none' }}
                                filename={`zema_report.csv`}
                                data={reportData}
                                target="_blank"
                            >
                                <h4 style={{ color: '#4456B7', marginTop: 2 }}>CSV (Excel)</h4>
                            </CSVLink>
                        </ListItem>
                    </List>
                )}
            </Dialog>
        </div>
    );
}
