import * as React from 'react';

import { CircularProgress, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { isRealData } from '../util';
import { useHistory } from 'react-router-dom';
import { useProfile } from '../providers/ProfileProvider';
import useSWR from 'swr';

const solidFoodColumnFormatter = v =>
    v?.value && Number.isInteger(v?.value) ? new Date(v.value * 1000).toLocaleDateString() : '';

const columns = [
    {
        field: 'subject_id',
        headerName: 'subID',
        width: 120,
        headerClassName: 'grid-header',
        renderCell: params => (
            <h4 style={{ color: '#4A1D40' }}>{(String(params.value) || '').toUpperCase()}</h4>
        )
    },
    {
        field: 'invite_code',
        headerName: 'eDiary ID',
        width: 120,
        headerClassName: 'grid-header',
        renderCell: params => (
            <h4 style={{ color: '#4A1D40', position: 'sticky', left: 0 }}>
                {(String(params.value) || '').toUpperCase()}
            </h4>
        )
    },
    {
        field: 'start_date',
        headerName: 'Start Date',
        width: 140,
        valueFormatter: v => new Date(v.value).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'study_day',
        headerName: 'Study Day',
        width: 120,
        headerClassName: 'grid-header',
        type: 'number'
    },
    {
        field: 'last_update',
        headerName: 'Last Activity',
        width: 140,
        valueFormatter: v =>
            new Date(v.value * 1000).toLocaleDateString() == 'Invalid Date'
                ? ''
                : new Date(v.value).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'compliance',
        headerName: 'Probio Taken',
        width: 110,
        headerClassName: 'grid-header'
    },
    {
        field: 'compliance_percent',
        headerName: 'Probio Rate',
        valueFormatter: v => `${v.value}${v.value === '-' ? '' : '%'}`,
        type: 'number',
        width: 110,
        headerClassName: 'grid-header'
    },

    { field: 'feeding', headerName: 'Feeding Surveys', width: 110, headerClassName: 'grid-header' },
    {
        field: 'feeding_percent',
        headerName: 'Feeding Completion',
        valueFormatter: v => `${v.value}${v.value === '-' ? '' : '%'}`,
        type: 'number',
        width: 110,
        headerClassName: 'grid-header'
    },
    {
        field: 'deviated_count',
        headerName: 'Too Much-Deviation',
        width: 110,
        headerClassName: 'grid-header',
        type: 'number'
    },
    { field: 'colic', headerName: 'Colic Surveys', width: 110, headerClassName: 'grid-header' },
    {
        field: 'colic_percent',
        headerName: 'Colic Completion',
        valueFormatter: v => `${v.value}${v.value === '-' ? '' : '%'}`,
        type: 'number',
        width: 110,
        headerClassName: 'grid-header'
    },

    { field: 'bisqr', headerName: 'BISQ-r Surveys', width: 110, headerClassName: 'grid-header' },
    {
        field: 'bisqr_percent',
        headerName: 'BISQ-r Completion',
        valueFormatter: v => `${v.value}${v.value === '-' ? '' : '%'}`,
        type: 'number',
        width: 110,
        headerClassName: 'grid-header'
    },

    { field: 'solids', headerName: 'Solids', width: 120, headerClassName: 'grid-header' },
    {
        field: 's1',
        headerName: 'S1',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's2',
        headerName: 'S2',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's3',
        headerName: 'S3',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's4',
        headerName: 'S4',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's5',
        headerName: 'S5',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's6',
        headerName: 'S6',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's7',
        headerName: 'S7',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 's8',
        headerName: 'S8',
        width: 100,
        headerClassName: 'grid-header',
        valueFormatter: solidFoodColumnFormatter
    },
    {
        field: 'id',
        headerName: 'Surveys Taken',
        width: 200,
        headerClassName: 'grid-header',
        renderCell: () => <h4 style={{ color: '#2B55D3', textDecoration: 'underline' }}>View</h4>
    },

    { field: 'lang', headerName: 'Lang', width: 70, headerClassName: 'grid-header' },
    { field: 'app_version', headerName: 'App', width: 120, headerClassName: 'grid-header' },
    { field: 'os_type', headerName: 'OS', width: 120, headerClassName: 'grid-header' },
    { field: 'os_version', headerName: 'Build', width: 120, headerClassName: 'grid-header' },
    {
        field: 'has_notification_permission',
        headerName: 'Notif. Permission',
        width: 150,
        valueFormatter: v => (v.value === true ? 'allowed' : v.value === false ? 'blocked' : '-'),
        headerClassName: 'grid-header'
    },
    {
        field: 'last_notification_time',
        headerName: 'Notif. Last Tapped',
        valueFormatter: v =>
            new Date(v.value * 1000).toLocaleDateString() == 'Invalid Date'
                ? ''
                : new Date(v.value * 1000).toLocaleDateString(),
        width: 150,
        headerClassName: 'grid-header'
    }
];

export default function ParticipantsPage() {
    console.log('init');
    const { data, error } = useSWR('participants');

    const { showTestData } = useProfile();

    const [rows, setRows] = useState([]);
    const history = useHistory();

    const [searchTerm, setSearchTerm] = useState();

    useEffect(() => {
        if (data && data.results) {
            // const regex = new RegExp(`/${searchTerm}/i/`);

            const testuser = user =>
                (user &&
                    user.invite_code &&
                    user.invite_code.indexOf &&
                    user.invite_code.indexOf(searchTerm) > -1) ||
                (user &&
                    user.subject_id &&
                    user.subject_id.indexOf &&
                    user.subject_id.indexOf(searchTerm) > -1);

            if (!showTestData) {
                setRows(
                    data.results
                        .map(record => ({ ...(record || {}) }))
                        .filter(
                            d =>
                                isRealData(d.invite_code) &&
                                ((searchTerm || '').length > 0 ? testuser(d) : true)
                        )
                );
            } else {
                setRows(
                    data.results
                        .map(record => ({ ...(record || {}) }))
                        .filter(
                            d =>
                                !isRealData(d.invite_code) &&
                                ((searchTerm || '').length > 0 ? testuser(d) : true)
                        )
                );
            }
        }
    }, [data, showTestData, searchTerm]);

    useEffect(() => {
        if (error) {
            console.log('[activity] request error: ', error);
        }
    }, [error]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100%'
            }}
        >
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <h2>ALL PARTICIPANTS</h2>
                </Grid>
                <Grid container xs={4} justify="flex-end" flex>
                    {data && data.results && (
                        <TextField
                            color="#4A1D40"
                            id="outlined-basic"
                            variant="outlined"
                            placeholder=" Search EDIARY IDs"
                            onChange={e =>
                                setSearchTerm(
                                    e.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
                                )
                            }
                        />
                    )}
                </Grid>
            </Grid>
            <Snackbar
                open={error}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{ backgroundColor: 'red', width: '100%' }}
            >
                <h4>Error: {error}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress />}
            {data && (
                <div style={{ height: '82vh' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        onCellClick={params => {
                            history.push(
                                `/participants/${(params.data || {}).id || (params.row || {}).id}`
                            );
                        }}
                    />
                    <div id="tr-footer" />
                </div>
            )}
        </div>
    );
}
