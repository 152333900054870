import { isLoggedIn, logIn, logOut } from './auth';

import { functions } from './firebase';

var count = 0;
async function apiClient(req) {
    count = count + 1;
    if (count > 100) {
        throw new Error('Too many requests');
    }
    try {
        console.log('[api] handling path: ', req);
        const parts = req.split('/');
        // TODO: fix this when its not monday night
        const result = await functions.httpsCallable(parts[0]).call(
            {},
            parts[2]
                ? {
                      [parts[1]]: parts[2],
                      ...(parts[3] ? { [parts[3]]: parts[4] } : {}),
                      ...(parts[5] ? { [parts[5]]: parts[6] } : {})
                  }
                : {}
        );
        console.log('[api] result: ', result.data);
        return result.data;
    } catch (e) {
        console.log('[api] REQUEST ERROR: ', e);
        // throw e;
    }
}

export { apiClient, isLoggedIn, logOut, logIn };
