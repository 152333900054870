import * as React from 'react';

import { CircularProgress, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { CSVLink } from 'react-csv';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
// import { GetActivities } from '../api';
import useSWR from 'swr';

const columns = [
    {
        field: 'completed_date',
        headerName: 'DATE TIME',
        width: 200,
        valueFormatter: v => new Date(v.value).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'available_date',
        headerName: 'ASKING ABOUT',
        width: 200,
        valueFormatter: v => new Date(v.value).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'type',
        headerName: 'SURVEY',
        width: 100,
        headerClassName: 'grid-header'
    },
    {
        field: 'version',
        headerName: 'VERSION',
        width: 20,
        headerClassName: 'grid-header'
    },
    {
        field: 'F1',
        headerName: 'F1',
        width: 100,
        headerClassName: 'grid-header',
        renderCell: params => (
            <h4
                style={{
                    ...(params.value == 3
                        ? { color: 'orange', fontWeight: 'bold' }
                        : { fontWeight: 'normal' })
                }}
            >
                {params.value || ''}
            </h4>
        )
    },
    { field: 'F2', headerName: 'F2', width: 100, headerClassName: 'grid-header' },
    { field: 'F3', headerName: 'F3', width: 100, headerClassName: 'grid-header' },
    { field: 'C1', headerName: 'C1', width: 100, headerClassName: 'grid-header' },
    { field: 'C2', headerName: 'C2', width: 100, headerClassName: 'grid-header' },
    { field: 'C3', headerName: 'C3', width: 100, headerClassName: 'grid-header' },
    { field: 'C4', headerName: 'C4', width: 100, headerClassName: 'grid-header' },
    { field: 'C5', headerName: 'C5', width: 100, headerClassName: 'grid-header' },
    { field: 'C6', headerName: 'C6', width: 100, headerClassName: 'grid-header' },
    {
        field: 'B1',
        headerName: 'B1',
        width: 100,
        headerClassName: 'grid-header'
    },
    { field: 'B2', headerName: 'B2', width: 100, headerClassName: 'grid-header' },
    { field: 'B3', headerName: 'B3', width: 100, headerClassName: 'grid-header' },
    { field: 'B4', headerName: 'B4', width: 100, headerClassName: 'grid-header' },
    { field: 'B5', headerName: 'B5', width: 100, headerClassName: 'grid-header' },
    { field: 'B6', headerName: 'B6', width: 100, headerClassName: 'grid-header' },
    { field: 'B7', headerName: 'B7', width: 100, headerClassName: 'grid-header' },
    { field: 'B8', headerName: 'B8', width: 100, headerClassName: 'grid-header' },
    { field: 'B9', headerName: 'B9', width: 100, headerClassName: 'grid-header' },
    { field: 'B10', headerName: 'B10', width: 100, headerClassName: 'grid-header' },
    { field: 'B11', headerName: 'B11', width: 100, headerClassName: 'grid-header' },
    { field: 'B12a', headerName: 'B12a', width: 100, headerClassName: 'grid-header' },
    { field: 'B12b', headerName: 'B12b', width: 100, headerClassName: 'grid-header' },
    { field: 'B12c', headerName: 'B12c', width: 100, headerClassName: 'grid-header' },
    { field: 'B12d', headerName: 'B12d', width: 100, headerClassName: 'grid-header' },
    { field: 'B12e', headerName: 'B12e', width: 100, headerClassName: 'grid-header' },
    { field: 'B13', headerName: 'B13', width: 100, headerClassName: 'grid-header' },
    { field: 'B14', headerName: 'B14', width: 100, headerClassName: 'grid-header' },
    { field: 'B15', headerName: 'B15', width: 100, headerClassName: 'grid-header' },
    { field: 'B16', headerName: 'B16', width: 100, headerClassName: 'grid-header' },
    { field: 'B17', headerName: 'B17', width: 100, headerClassName: 'grid-header' },
    { field: 'B18', headerName: 'B18', width: 100, headerClassName: 'grid-header' },
    { field: 'B19', headerName: 'B19', width: 100, headerClassName: 'grid-header' },
    { field: 'B20', headerName: 'B20', width: 100, headerClassName: 'grid-header' }
];

export default function ResponsesPage() {
    console.log('init');

    const [rows, setRows] = useState([]);
    let { id } = useParams();

    const { data, error } = useSWR(id ? `responses/user_id/${id}` : null);

    useEffect(() => {
        if (data && data.results) {
            setRows(data.results);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            console.log('[activity] request error: ', error);
        }
    }, [error]);

    return (
        <div style={{ height: 400, width: '100%', display: 'block' }}>
            <Grid container xs={12}>
                <Grid item xs={9}>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        style={{ marginBottom: 30, marginTop: 30, fontWeight: '600' }}
                    >
                        <Link color="inherit" href="/participants">
                            ALL PARTICIPANTS
                        </Link>
                        {data && data.results && data.results[0] && (
                            <Typography
                                color="textPrimary"
                                style={{ color: '#4A1D40', fontWeight: '600' }}
                            >
                                {(data.results[0].edid || '').toUpperCase()}
                            </Typography>
                        )}
                    </Breadcrumbs>
                </Grid>
                <Grid container xs={3} justify="flex-end" flex>
                    <CSVLink filename={`zema_${id}.csv`} data={rows} target="_blank">
                        <h4 style={{ color: '#2B55D3' }}>Download CSV</h4>
                    </CSVLink>
                </Grid>
            </Grid>

            <Grid container xs={12}>
                <Grid item xs={9}>
                    {}
                </Grid>
            </Grid>

            <Snackbar
                open={error}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{ backgroundColor: 'red', width: '100%' }}
            >
                <h4>Error: {error}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress />}
            {data && (
                <div style={{ height: '80vh' }}>
                    {' '}
                    <DataGrid rows={rows} columns={columns} pageSize={50} />{' '}
                </div>
            )}
        </div>
    );
}
