import * as React from 'react';

import { Button, CircularProgress, Grid, Input, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { DatePicker } from '@material-ui/pickers';
import Dropdown from 'react-dropdown';
import { isRealData } from '../util';
import { useHistory } from 'react-router-dom';
import { useProfile } from '../providers/ProfileProvider';
import useSWR from 'swr';

function titleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(x => (x[0] || '').toUpperCase() + x.slice(1))
        .join(' ');
}

function timestamp(string) {
    return Math.floor(Date.parse(string) / 1000);
}

export default function ActivityPage() {
    const { data, error } = useSWR('activities');

    const { showTestData } = useProfile();
    const [rows, setRows] = useState([]);
    const history = useHistory();

    const blankFilters = { start: null, end: null, eid: null, type: 'all' };
    const [filteredRows, setFilteredRows] = useState([]);
    const [filters, setFilters] = useState(blankFilters);

    const activities = activity =>
        ({
            sign_up: {
                description: `Created Zema eDiary Profile & Schedule`
            },
            completed_survey: {
                description: `Completed ${titleCase(
                    ((activity || {}).value || {}).name || ' '
                )} Survey for ${new Date(
                    ((activity || {}).value || {}).scheduled_for
                ).toLocaleDateString()}`
            },
            logged_solid: {
                description: `Solid food ${titleCase(
                    ((activity || {}).value || {}).name || ' '
                )} introduced to child on ${new Date(
                    ((activity || {}).value || {}).introduced_on || ' '
                ).toLocaleDateString()}`
            }
        }[activity.description]);

    const activityTypes = [
        {
            label: <p>All Activities</p>,
            value: 'all'
        },
        {
            label: <p>Created eDiary Profile</p>,
            value: 'sign_up'
        },
        {
            label: <p>Completed Survey</p>,
            value: 'completed_survey'
        },
        {
            label: <p>Solid Food Introduced</p>,
            value: 'logged_solid'
        }
    ];

    const columns = [
        {
            field: 'timestamp',
            headerName: 'DATE TIME',
            width: 200,
            valueFormatter: v => new Date(v.value).toLocaleDateString(),
            headerClassName: 'grid-header'
        },
        {
            field: 'invite_code',
            headerName: 'PARTICIPANT',
            width: 150,
            headerClassName: 'grid-header',
            renderCell: params => (
                <h4 style={{ color: '#4A1D40' }}>{(String(params.value) || '').toUpperCase()}</h4>
            )
        },
        {
            field: 'subject_id',
            headerName: 'subID',
            width: 100,
            headerClassName: 'grid-header',
            renderCell: params => (
                <h4 style={{ color: '#4A1D40' }}>{(String(params.value) || '').toUpperCase()}</h4>
            )
        },
        {
            field: 'study_day',
            headerName: 'STUDY DAY',
            width: 120,
            headerClassName: 'grid-header'
        },
        {
            field: 'id',
            headerName: 'ACTIVITY',
            sortable: false,
            width: 350,
            headerClassName: 'grid-header',
            valueFormatter: v =>
                activities(filteredRows.find(row => row.id === v.value)).description
        }
    ];

    useEffect(() => {
        if (data && data.results) {
            const mapped = data.results
                .map(record => ({ ...record }))
                .filter(row =>
                    showTestData ? !isRealData(row.invite_code) : isRealData(row.invite_code)
                );
            setRows(mapped);
        }
    }, [data, showTestData]);

    useEffect(() => {
        // start: null, end: null, eid: null, type: null
        const f = rows.filter(r => {
            if (filters.start !== null) {
                if (timestamp(r.timestamp) <= Math.floor(filters.start)) {
                    console.log('fail', r.timestamp);
                    return false;
                }
            }
            if (filters.end !== null) {
                if (timestamp(r.timestamp) > Math.floor(filters.end)) {
                    console.log('fail', r.timestamp);
                    return false;
                }
            }
            if (filters.eid !== null) {
                if (!new RegExp(filters.eid, 'gi').test(r.invite_code)) {
                    console.log('fail', r.timestamp);
                    return false;
                }
            }
            if (filters.type !== null && filters.type !== 'all') {
                if (!new RegExp(filters.type, 'gi').test(r.description)) {
                    console.log('fail', r.timestamp);
                    return false;
                }
            }
            return true;
        });
        setFilteredRows(f);
    }, [filters, rows]);

    useEffect(() => {
        if (error) {
            console.log('[activity] request error: ', error);
        }
    }, [error]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 0
            }}
            className="activity-page"
        >
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <h2>ALL ACTIVITY</h2>
                </Grid>
            </Grid>
            <Grid
                className={'filter-bar'}
                container
                xs={12}
                style={{
                    marginBottom: 30,
                    backgroundColor: '#EDEDED',
                    padding: 16,
                    paddingTop: 0,
                    textAlign: 'left'
                }}
                alignItems="flex-start"
            >
                <Grid item xs={2}>
                    <p>Start Date</p>
                    <DatePicker
                        clearable
                        id="filter-field"
                        value={filters.start ? (filters.start || 0) * 1000 : null}
                        format="dd/MM/yyyy"
                        views={['year', 'month', 'date']}
                        variant="inline"
                        onChange={v => setFilters({ ...filters, start: timestamp(v) })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <p>End Date</p>
                    <DatePicker
                        id="filter-field"
                        value={filters.end ? (filters.end || 0) * 1000 : null}
                        format="dd/MM/yyyy"
                        views={['year', 'month', 'date']}
                        variant="inline"
                        onChange={v => setFilters({ ...filters, end: timestamp(v) })}
                    />
                </Grid>
                <Grid item xs={1}>
                    <p>eDiary ID</p>
                    <Input
                        id="filter-field"
                        label="EID"
                        value={filters.eid}
                        onChange={v => setFilters({ ...filters, eid: v.target.value })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <p>Type</p>
                    <Dropdown
                        options={activityTypes}
                        value={filters.type}
                        style={{ height: '10px' }}
                        onChange={v => setFilters({ ...filters, type: v.value })}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        style={{ marginTop: 48, height: 44, width: 100 }}
                        onClick={() => setFilters(blankFilters)}
                    >
                        CLEAR
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={error}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{ backgroundColor: 'red', width: '100%' }}
            >
                <h4>Error: {error}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress />}
            {data && (
                <div style={{ height: '73vh' }}>
                    <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        pageSize={50}
                        onCellClick={params => {
                            history.push(
                                `/participants/${(params.data || {}).uid || (params.row || {}).uid}`
                            );
                        }}
                        disableColumnFilter
                    />
                </div>
            )}
        </div>
    );
}
